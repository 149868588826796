.sdds-navbar-menu-list .sdds-navbar-menu-item,
.sdds-navbar-menu-list .sdds-navbar-menu-item.active,
.sdds-navbar-menu-list .sdds-navbar-menu-item:active,
.sdds-navbar-menu-list .sdds-navbar-menu-item:visited {
  --sdds-link: var(--sdds-white);
  --sdds-link-visited: var(--sdds-white);
  --sdds-link-focus: var(--sdds-white);
  --sdds-link-focus-border: none;
  --sdds-link-text-decoration: none;
}
